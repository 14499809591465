import HorizontalList from "../Components/HorizontalList/HorizontalList";
import React from "react";

export default function PrayerTimes(props) {
  return (
    <>
      <HorizontalList user={props.user} />
    </>
  );
}
